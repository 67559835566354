body {
    background-color: #f3f9ff !important;
}


:root {
    --primary-color: #1592ff;
    --secondary-color: #fff;
    --primary-text-color: #2c3e50;
    --top-nav-height: 0px;
    --margin: 0px;
    --sidebar-width: 90px;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}




/*#region Sidebar */

.sidebar {
    position: fixed;
    left: var(--margin);
    bottom: var(--margin);
    top: 50px;
    width: 237px;
    background: var(--secondary-color);
    border-bottom-left-radius: 20px;
    transition: all .5s ease;
}

    .sidebar ul {
        padding: 0 10px;
        margin-top: 10px;
    }

        .sidebar ul li a {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary-text-color);
            font-size: 1rem;
            padding: 1rem;
            font-weight: 600;
            margin-bottom: 5px;
            height: 70px;
        }

            .sidebar ul li a span.icon {
                font-size: 1.7rem;
            }

            .sidebar ul li a.active {
                color: var(--primary-color);
            }

            .sidebar ul li a.active {
                background-color: #1592ff;
                color: #fff;
                border-radius: 8px;
            }

        .sidebar ul li.active .icon,
        .sidebar ul li.active .title {
            color: #fff;
        }

        .sidebar ul li:hover:not(.profile) {
            background-color: #1592ff;
            color: #fff;
            border-radius: 8px;
        }


        .sidebar ul li:hover .icon,
        .sidebar ul li:hover .title {
            color: #fff;
        }

        .sidebar ul li a span.icon {
            margin-right: 10px;
            display: inline-block;
        }

        .sidebar ul li a span.title {
            display: inline-block;
        }

    .sidebar.collapse {
        width: 80px;
    }

        .sidebar.collapse ul li a {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            height: 50px;
            margin-bottom: 15px;
        }

            .sidebar.collapse ul li a span.icon {
                margin: 0;
                text-align: center;
                font-size: 1.7rem;
            }

            .sidebar.collapse ul li a span.title {
                font-size: 0.6rem;
                text-align: center;
                display: none;
            }


    /*#endregion */


    /*#region user-profile */

    .sidebar .user-profile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: var(--primary-text-color);
        font-size: 1rem;
        padding: 1rem 0 1rem 1.5rem;
        font-weight: 600;
        align-content: flex-start;
        justify-content: center;
        margin-top: 40px;
    }

        .sidebar .user-profile .user-details {
            margin-top: 10px;
        }

    .sidebar.collapse .user-profile {
        padding: .4em;
    }

    .sidebar .user-profile .user-details label {
        font-size: 1.2em;
    }

    .sidebar.collapse .user-profile .user-details {
        font-size: 0.6rem;
        text-align: center;
        display: none;
    }

.profile-picture {
    border-radius: 50% !important;
    height: 70px !important;
    width: 70px !important;
}


/*#endregion */

/*#region main canvas */

.app-wrapper {
    position: fixed;
    top: 90px;
    left: 240px;
    bottom: var(--margin);
    right: var(--margin);
    padding: 2rem 1rem;
    background-color: #f3f9ff !important;
    border-bottom-right-radius: 20px;
    transition: all 0.3s ease;
}

    .app-wrapper .item {
        background: #fff;
        margin-bottom: 10px;
        padding: 15px;
        font-size: 14px;
        line-height: 22px;
    }

    .app-wrapper.collapse {
        left: 90px;
        top: 60px;
    }

.app-wrapper {
    top: 60px;
}


/*#endregion */


/*region Social Media Dropdown list*/
.social-media-item {
    margin-bottom: 0 !important;
}
/*endregion*/
